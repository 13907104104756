<script setup lang="ts">
import { type WritableComputedRef } from 'vue'
import { useLocalizationStore } from '~/store/localization/localization'
import Localization from '~/constants/localization/localization'
import { type ILocalizationLanguage } from '~/store/localization/types/LocalizationTypes'

const { locale } = useI18n()
const localizationStore = useLocalizationStore()

onMounted(() => {
  // localizationStore.resetState();
  locale.value = localizationStore.getSelectedLanguage().literal
})

const selectedLanguage: WritableComputedRef<ILocalizationLanguage> = computed({
  get: (): ILocalizationLanguage => localizationStore.getSelectedLanguage(),
  set: (value: ILocalizationLanguage): void => {
    localizationStore.setLanguage(value)
    locale.value = value.literal
  },
})
</script>

<template>
  <div style="width: 150px">
    <v-select v-model="selectedLanguage" :items="localizationStore.getAvailableLanguages()" density="compact" :label="$t(Localization.selectALanguage)" :hide-details="true" :return-object="true" :item-title="'emoji'">
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.raw.literal">
          <template #title>
            <label>{{ $t(item.raw.title) }}</label>
          </template>
          <template #append>
            <Icon :name="item.raw.emoji"></Icon>
          </template>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<style scoped lang="sass"></style>

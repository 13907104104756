<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useUserPreferences } from '@/store/userPreferences/userPreferences'
const userPreferences = useUserPreferences()
const theme = useTheme()
const changeTheme = () => {
  const themeToChange = theme.global.current.value.dark ? 'light' : 'dark'
  theme.global.name.value = themeToChange
  userPreferences.setSelectedTheme(themeToChange)
}

onMounted(() => {
  theme.global.name.value = userPreferences.selectedTheme
})
</script>

<template>
  <v-btn :icon="userPreferences.getSelectedTheme !== 'dark' ? 'mdi-moon-waxing-crescent' : 'mdi-white-balance-sunny'" size="x-large" @click="changeTheme"></v-btn>
</template>
